import React from 'react';
import { Link } from 'react-router-dom';
import styles from './CheckEmail.module.css';

const CheckEmail = () => {
  return (
    <div className={styles.checkEmail}>
      <h2>Check Your Email</h2>
      <p>We've sent you an email with a link to confirm your account. Please check your inbox and click the link to complete your registration.</p>
      <p>If you don't see the email, please check your spam folder.</p>
      <Link to="/signin" className={styles.link}>Back to Sign In</Link>
    </div>
  );
};

export default CheckEmail;