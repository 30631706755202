import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import styles from './PetOwnerDashboard.module.css';

const PetOwnerDashboard = () => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserAndProfile();
  }, []);

  const fetchUserAndProfile = async () => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      setUser(user);

      let { data: profile, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      setProfile(profile);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading dashboard...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className={styles.dashboard}>
      <h1>Welcome, {profile?.full_name || user?.email}</h1>
      <div className={styles.dashboardContent}>
        <section className={styles.quickLinks}>
          <h2>Quick Links</h2>
          <ul>
            <li><Link to="/search">Find a Groomer</Link></li>
            <li><Link to="/pet-owner-profile">Edit Profile</Link></li>
            <li><Link to="/bookings">My Bookings</Link></li>
            <li><Link to="/pets">My Pets</Link></li>
          </ul>
        </section>
        <section className={styles.upcomingBookings}>
          <h2>Upcoming Bookings</h2>
          {/* We'll implement this later */}
          <p>No upcoming bookings</p>
        </section>
        <section className={styles.recentActivity}>
          <h2>Recent Activity</h2>
          {/* We'll implement this later */}
          <p>No recent activity</p>
        </section>
      </div>
    </div>
  );
};

export default PetOwnerDashboard;