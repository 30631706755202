import React, { useState } from 'react';
import { LoadScript } from '@react-google-maps/api';
import SearchForm from './SearchForm';
import SearchResults from './SearchResults';

const libraries = ['places'];

const GroomerSearch = () => {
  const [results, setResults] = useState([]);

  const handleSearch = (searchParams) => {
    console.log('Search params:', searchParams);
    // Mock search results with lat and lng
    const mockResults = [
      { id: 1, name: 'Jane Doe', lat: 37.7749, lng: -122.4194, services: ['Dog Grooming', 'Dog Boarding'] },
      { id: 2, name: 'John Smith', lat: 34.0522, lng: -118.2437, services: ['Dog Walking', 'Drop-In Visits'] },
      { id: 3, name: 'Alice Johnson', lat: 41.8781, lng: -87.6298, services: ['Dog Training', 'Dog Boarding'] },
    ];
    
    const filteredResults = mockResults.filter(groomer => {
      const distance = calculateDistance(searchParams.lat, searchParams.lng, groomer.lat, groomer.lng);
      return distance <= 50 && (!searchParams.service || groomer.services.includes(searchParams.service));
    });
    
    setResults(filteredResults);
  };

  // Haversine formula to calculate distance between two points
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const d = R * c; // Distance in km
    return d;
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI/180);
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyAVWuLu22OuEBtQAEHcZ06hQvoulLuDcC0" libraries={libraries}>
      <div>
        <h1>Find a Groomer</h1>
        <SearchForm onSearch={handleSearch} />
        <SearchResults results={results} />
      </div>
    </LoadScript>
  );
};

export default GroomerSearch;