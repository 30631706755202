import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import styles from './Header.module.css';

const Header = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setUser(session?.user ?? null);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    setUser(null);
    navigate('/');
  };

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <Link to="/">PoopyButts</Link>
      </div>
      <nav className={styles.nav}>
        <ul className={styles.navList}>
          <li><Link to="/search">Find a Groomer</Link></li>
          <li><Link to="/become-groomer">Become A Groomer</Link></li>
          {user ? (
            <>
              <li>
                <Link to={user.user_metadata.user_type === 'groomer' ? '/groomer-profile' : '/pet-owner-profile'}>
                  My Profile
                </Link>
              </li>
              {user.user_metadata.user_type === 'groomer' && (
                <li>
                  <Link to="/groomer-dashboard">Dashboard</Link>
                </li>
              )}
              <li><button onClick={handleSignOut} className={styles.authButton}>Sign Out</button></li>
            </>
          ) : (
            <>
              <li><button onClick={() => navigate('/signin')} className={styles.authButton}>Sign In</button></li>
              <li><button onClick={() => navigate('/signup')} className={styles.authButton}>Sign Up</button></li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;