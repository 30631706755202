import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import styles from './GroomerDashboard.module.css';

const GroomerDashboard = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      console.log('Fetching profile data...');
      const { data: { user } } = await supabase.auth.getUser();
      console.log('User data:', user);
      
      if (user) {
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', user.id)
          .single();
        
        if (error) throw error;

        if (data) {
          console.log('Profile data:', data);
          if (data.is_groomer) {
            setProfile(data);
          } else {
            setError('This dashboard is for groomers only.');
          }
        } else {
          throw new Error('No profile data found');
        }
      } else {
        throw new Error('No user found');
      }
    } catch (error) {
      console.error('Error fetching profile data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!profile) return <div>No groomer profile found</div>;

  return (
    <div className={styles.dashboard}>
      <h1>Welcome, {profile.full_name || 'Groomer'}!</h1>
      <p>Services: {profile.services ? profile.services.join(', ') : 'No services listed'}</p>
      {/* Add more groomer-specific information here */}
      <button onClick={() => {/* Add logic to edit profile */}}>Edit Profile</button>
    </div>
  );
};

export default GroomerDashboard;