import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import ProfileEditForm from './ProfileEditForm';
import styles from './PetOwnerProfile.module.css';

const PetOwnerProfile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user found');

      let { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      setProfile(data);
    } catch (error) {
      console.error('Error fetching profile:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleProfileUpdate = async (updatedProfile) => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('profiles')
        .update(updatedProfile)
        .eq('id', profile.id)
        .single();

      if (error) throw error;
      setProfile(data);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading profile...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!profile) return <div>No profile found</div>;

  return (
    <div className={styles.profile}>
      <h2>Pet Owner Profile</h2>
      {isEditing ? (
        <ProfileEditForm profile={profile} onProfileUpdate={handleProfileUpdate} />
      ) : (
        <>
          <div className={styles.profileInfo}>
            <p><strong>Name:</strong> {profile.full_name || 'Not set'}</p>
            <p><strong>Phone:</strong> {profile.phone || 'Not set'}</p>
            <p><strong>Location:</strong> {profile.location || 'Not set'}</p>
          </div>
          <button className={styles.editButton} onClick={() => setIsEditing(true)}>Edit Profile</button>
        </>
      )}
    </div>
  );
};

export default PetOwnerProfile;