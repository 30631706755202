import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.column}>
          <h3>PoopyButts</h3>
          <p>Find trusted pet sitters and dog walkers in your neighborhood.</p>
        </div>
        <div className={styles.column}>
          <h4>Quick Links</h4>
          <ul>
            <li><a href="#search">Search Sitters</a></li>
            <li><a href="#become-sitter">Become a Sitter</a></li>
            <li><a href="#services">Our Services</a></li>
            <li><a href="#about">About Us</a></li>
          </ul>
        </div>
        <div className={styles.column}>
          <h4>Contact Us</h4>
          <p>Email: info@poopybutts.com</p>
          <p>Phone: (555) 123-4567</p>
        </div>
      </div>
      <div className={styles.bottom}>
        <p>&copy; 2023 PoopyButts. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;