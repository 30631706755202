import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import styles from './GroomerSignUp.module.css';

const GroomerSignUp = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    services: {
      dogGrooming: false,
      dogWalking: false,
      dogBoarding: false,
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? {
        ...prevState[name],
        [value]: checked
      } : value
    }));
  };

  const handleCaptchaVerify = (token) => {
    setCaptchaToken(token);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords don't match");
      setLoading(false);
      return;
    }

    try {
      const { data, error } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            user_type: 'groomer',
          },
          captchaToken: captchaToken
        }
      });

      if (error) throw error;

      if (data) {
        // Add groomer details to the 'profiles' table
        const { error: profileError } = await supabase
          .from('profiles')
          .insert([
            {
              id: data.user.id,
              full_name: formData.name,
              phone_number: formData.phoneNumber,
              services: Object.keys(formData.services).filter(key => formData.services[key]),
              is_groomer: true // Ensure this field is set to true
            }
          ]);

        if (profileError) throw profileError;

        console.log('Groomer signup successful:', data);
        navigate('/check-email');
      }
    } catch (error) {
      console.error('Groomer signup error:', error);
      setError(error.message || 'An error occurred during signup');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.signUp}>
      <h2>Groomer Sign Up</h2>
      {error && <p className={styles.error}>{error}</p>}
      <form onSubmit={handleSignUp}>
        <div className={styles.formGroup}>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="confirmPassword">Confirm Password:</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label>Services Offered:</label>
          <div className={styles.checkboxGroup}>
            <label>
              <input
                type="checkbox"
                name="services"
                value="dogGrooming"
                checked={formData.services.dogGrooming}
                onChange={handleChange}
              /> Dog Grooming
            </label>
            <label>
              <input
                type="checkbox"
                name="services"
                value="dogWalking"
                checked={formData.services.dogWalking}
                onChange={handleChange}
              /> Dog Walking
            </label>
            <label>
              <input
                type="checkbox"
                name="services"
                value="dogBoarding"
                checked={formData.services.dogBoarding}
                onChange={handleChange}
              /> Dog Boarding
            </label>
          </div>
        </div>
        <div className={styles.hcaptcha}>
          <HCaptcha
            sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
            onVerify={handleCaptchaVerify}
            ref={captchaRef}
          />
        </div>
        <button type="submit" className={styles.submitButton} disabled={loading || !captchaToken}>
          {loading ? 'Signing Up...' : 'Sign Up as Groomer'}
        </button>
      </form>
    </div>
  );
};

export default GroomerSignUp;