import React from 'react';

const SearchResults = ({ results }) => {
  if (!results || results.length === 0) {
    return <p>No groomers found. Try adjusting your search criteria.</p>;
  }

  return (
    <div>
      <h2>Search Results</h2>
      <ul>
        {results.map((groomer) => (
          <li key={groomer.id}>
            {groomer.name} - Services: {groomer.services.join(', ')}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchResults;