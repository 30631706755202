import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import ProfileEditForm from './ProfileEditForm';
import styles from './GroomerProfile.module.css';

const GroomerProfile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        // Redirect to login page or show an error
        setError('Please log in to view your profile');
      } else {
        fetchProfile();
      }
    };
    checkUser();
  }, []);

  const fetchProfile = async () => {
    try {
      console.log('Fetching profile...');
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      console.log('User data:', user);
      if (!user) throw new Error('No user found');

      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      console.log('Profile data:', data);
      console.log('Profile error:', error);

      if (error) throw error;
      if (!data) throw new Error('No profile data found');
      
      setProfile(data);
    } catch (error) {
      console.error('Error in fetchProfile:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleProfileUpdate = (updatedProfile) => {
    setProfile(updatedProfile);
    setIsEditing(false);
  };

  if (loading) return <div className={styles.loading}>Loading profile...</div>;
  if (error) return <div className={styles.error}>Error: {error}</div>;
  if (!profile) return <div className={styles.noProfile}>No profile found</div>;

  return (
    <div className={styles.profileContainer}>
      {isEditing ? (
        <ProfileEditForm profile={profile} onProfileUpdate={handleProfileUpdate} />
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.avatarContainer}>
              <img src={profile.avatar_url || '/default-avatar.png'} alt="Profile" className={styles.avatar} />
            </div>
            <div className={styles.headerInfo}>
              <h1>{profile.full_name || 'No Name'}</h1>
              <p className={styles.location}>
                {profile.location || 'No Location'} - {profile.zip_code || 'No Zip Code'}
              </p>
              <div className={styles.ratingContainer}>
                <span className={styles.rating}>4.9</span>
                <span className={styles.reviewCount}>(50 reviews)</span>
              </div>
              <p className={styles.languages}>Languages: English, Spanish</p>
              <button className={styles.editButton} onClick={() => setIsEditing(true)}>Edit Profile</button>
            </div>
          </div>
          
          <div className={styles.mainContent}>
            <div className={styles.leftColumn}>
              <div className={styles.aboutSection}>
                <h2>About Me</h2>
                <p>{profile.bio || 'No bio available'}</p>
              </div>
              <div className={styles.servicesSection}>
                <h2>Services</h2>
                {profile.services && profile.services.length > 0 ? (
                  <ul>
                    {profile.services.split(',').map((service, index) => (
                      <li key={index}>{service.trim()}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No services listed</p>
                )}
              </div>
            </div>
            
            <div className={styles.rightColumn}>
              <div className={styles.bookingForm}>
                <h2>Book a Service</h2>
                {/* Add your booking form here */}
                <p>Booking form placeholder</p>
              </div>
            </div>
          </div>

          <div className={styles.reviewsSection}>
            <h2>Reviews</h2>
            {/* Add your reviews component here */}
            <p>Reviews placeholder</p>
          </div>
        </>
      )}
    </div>
  );
};

export default GroomerProfile;