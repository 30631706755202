import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import styles from './App.module.css';
import CheckEmail from './components/CheckEmail';
import UserDashboard from './components/UserDashboard';
import GroomerDashboard from './components/GroomerDashboard';
import PetOwnerProfile from './components/PetOwnerProfile';
import GroomerProfile from './components/GroomerProfile';
import PetOwnerDashboard from './components/PetOwnerDashboard';
import GroomerSearch from './components/GroomerSearch';
import BecomeGroomer from './components/BecomeGroomer';
import GroomerSignUp from './components/GroomerSignUp';
import TestGroomerSignUp from './TestGroomerSignUp';

// Lazy load components
const HeroSection = lazy(() => import('./components/HeroSection'));
const ServicesSection = lazy(() => import('./components/ServicesSection'));
const HowItWorksSection = lazy(() => import('./components/HowItWorksSection'));
const FeaturedSitters = lazy(() => import('./components/FeaturedSitters'));
const TestimonialsSection = lazy(() => import('./components/TestimonialsSection'));
const ListingPage = lazy(() => import('./components/ListingPage'));
const SearchResults = lazy(() => import('./components/SearchResults'));
const SignUp = lazy(() => import('./components/SignUp'));
const SignIn = lazy(() => import('./components/SignIn'));
const ProtectedRoute = lazy(() => import('./components/ProtectedRoute'));
const WelcomePage = lazy(() => import('./components/WelcomePage'));
const ConfirmEmail = lazy(() => import('./components/ConfirmEmail'));

function App() {
  return (
    <Router>
      <div className={styles.appWrapper}>
        <Helmet>
          <title>PoopyButts - Pet Grooming Services</title>
          <meta name="description" content="Find the best pet groomers near you with PoopyButts" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="theme-color" content="#000000" />
          <link rel="canonical" href="https://your-domain.com" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "PoopyButts",
              "description": "Find the best pet groomers near you",
              "url": "https://your-domain.com",
              "sameAs": [
                "https://www.facebook.com/poopybutts",
                "https://www.instagram.com/poopybutts"
              ]
            })}
          </script>
        </Helmet>
        <Header />
        <main className={styles.mainContent}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={
                <>
                  <HeroSection />
                  <ServicesSection />
                  <HowItWorksSection />
                  <FeaturedSitters />
                  <TestimonialsSection />
                </>
              } />
              <Route path="/listing/:id" element={<ListingPage />} />
              <Route path="/search" element={<SearchResults />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/groomer-dashboard" element={
                <ProtectedRoute>
                  <GroomerDashboard />
                </ProtectedRoute>
              } />
              <Route path="/welcome" element={<WelcomePage />} />
              <Route path="/confirm-email" element={<ConfirmEmail />} />
              <Route path="/check-email" element={<CheckEmail />} />
              <Route path="/pet-owner-profile" element={<PetOwnerProfile />} />
              <Route path="/groomer-profile" element={<GroomerProfile />} />
              
              {/* Add the new dashboard route */}
              <Route path="/dashboard" element={<PetOwnerDashboard />} />
              
              {/* You can keep the old dashboard route if needed, or remove it */}
              {/* <Route path="/pet-owner-dashboard" element={<OldPetOwnerDashboard />} /> */}
              
              {/* Add the new BecomeGroomer route */}
              <Route path="/become-groomer" element={<BecomeGroomer />} />
              
              {/* Add the new GroomerSignUp route */}
              <Route path="/groomer-signup" element={<GroomerSignUp />} />
              
              {/* Add the new TestGroomerSignUp route */}
              <Route path="/test-groomer-signup" element={<TestGroomerSignUp />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;