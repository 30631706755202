import React from 'react';
import { Link } from 'react-router-dom';
import styles from './BecomeGroomer.module.css';

const BecomeGroomer = () => {
  return (
    <div className={styles.becomeGroomerContainer}>
      <h1 className={styles.title}>Become a Groomer</h1>
      <section className={styles.infoSection}>
        <h2>Why become a groomer with PoopyButts?</h2>
        <ul>
          <li>Set your own schedule and prices</li>
          <li>Meet adorable pets and their owners</li>
          <li>Earn money doing what you love</li>
          <li>Access to our growing network of pet owners</li>
        </ul>
      </section>
      <section className={styles.requirementsSection}>
        <h2>Requirements</h2>
        <ul>
          <li>Must be 18 years or older</li>
          <li>Have relevant experience or certification in pet grooming</li>
          <li>Pass a background check</li>
          <li>Have a smartphone to use our app</li>
        </ul>
      </section>
      <section className={styles.ctaSection}>
        <h2>Ready to start?</h2>
        <p>Join our community of professional groomers today!</p>
        <Link to="/groomer-signup" className={styles.signupButton}>Sign Up as a Groomer</Link>
      </section>
    </div>
  );
};

export default BecomeGroomer;